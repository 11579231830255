import React from 'react';

const IconLoader = () => (
  <svg
    id="logo"
    xmlns="http://www.w3.org/2000/svg"
    width="500"
    viewBox="0 0 375 340"
    height="500"
    version="1.0">
    <defs>
      <g />
    </defs>
    <g fill="#fff">
      <g transform="translate(88.340238, 292.648915)">
        <g>
          <path d="M 13.921875 0 L 13.921875 -10.65625 C 25.765625 -11.550781 33.535156 -13.234375 37.234375 -15.703125 C 40.941406 -18.171875 42.796875 -22.460938 42.796875 -28.578125 L 42.796875 -179.484375 C 42.796875 -191.429688 33.566406 -198.144531 15.109375 -199.625 L 15.109375 -210.140625 L 112.25 -210.140625 C 129.820312 -210.140625 144.359375 -206.164062 155.859375 -198.21875 C 167.367188 -190.269531 173.125 -178.992188 173.125 -164.390625 C 173.125 -138.222656 156.585938 -121.238281 123.515625 -113.4375 L 123.515625 -112.25 C 164.285156 -106.132812 184.671875 -87.722656 184.671875 -57.015625 C 184.671875 -39.835938 177.785156 -26.039062 164.015625 -15.625 C 150.242188 -5.207031 133.484375 0 113.734375 0 Z M 74.34375 -197.703125 L 74.34375 -117.140625 L 93.15625 -117.140625 C 122.875 -117.140625 137.734375 -131.898438 137.734375 -161.421875 C 137.734375 -186.003906 124.597656 -198.296875 98.328125 -198.296875 C 89.441406 -198.296875 81.445312 -198.097656 74.34375 -197.703125 Z M 74.34375 -104.703125 L 74.34375 -30.65625 C 74.34375 -18.113281 83.820312 -11.84375 102.78125 -11.84375 C 133.09375 -11.84375 148.25 -28.726562 148.25 -62.5 C 148.25 -75.53125 143.953125 -85.921875 135.359375 -93.671875 C 126.765625 -101.421875 115.015625 -105.296875 100.109375 -105.296875 C 88.554688 -105.296875 79.96875 -105.097656 74.34375 -104.703125 Z M 74.34375 -104.703125 " />
        </g>
      </g>
    </g>
  </svg>
);

export default IconLoader;
